import React from 'react'

import { Skeleton as InterSkeleton } from '@interco/inter-ui/components/Skeleton'

import { SkeletonContainer } from './styles'

type SkeletonProps = {
  height: string
}

export const Skeleton = ({ height }: SkeletonProps) => (
  <SkeletonContainer style={{ height }}>
    <InterSkeleton height={height} width="100%" />
  </SkeletonContainer>
)
