import { AppContext, AppLanguage } from 'config/enums'

import { Theme } from '@interco/inter-ui'

export const getContext = (application: string, organization: string) => {
  if (organization === 'US') return AppContext.IA
  if (application === 'PJ') return AppContext.PJ

  return AppContext.PF
}

export const getLanguage = (context: AppContext, language: string) => {
  if (context === AppContext.IA) {
    if (language.startsWith(AppLanguage.EN)) return AppLanguage.EN
    if (language.startsWith(AppLanguage.ES)) return AppLanguage.ES
  }

  return AppLanguage.PT
}

export const getTheme = (application: string) => (application === 'PJ' ? Theme.PJ : Theme.PF)
