import styled from 'styled-components'

export const ArticleContainer = styled.div`
  padding: 16px 24px 24px 24px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const ArticleTitle = styled.h3`
  color: var(--gray500);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
  margin-top: 0;
`

export const ArticleContent = styled.div`
  color: var(--gray400);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  word-break: break-word;

  ol,
  ul {
    padding-left: 1rem;
  }

  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }
`

export const FeedbackContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
  width: 100%;
`

export const FeedbackMessage = styled.span`
  color: var(--gray500);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`

export const FeedBackButtonsContainer = styled.div`
  align-items: center;
  display: flex;

  button {
    &:first-child {
      margin-right: 24px;
    }
  }
`

export const FeedbackButton = styled.button`
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  padding: 8px;
`

export const FeedbackImage = styled.img``
