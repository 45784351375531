import { CDN_STATIC_FAQ_BABI_URL } from 'config/urls'
import { AppContext, AppLanguage } from 'config/enums'

import { Category } from '@types'

type CategoriesResponse = {
  [AppLanguage.EN]: Category[]
  [AppLanguage.ES]: Category[]
  [AppLanguage.PT]: Category[]
}

export function fetchCategory(
  context: AppContext,
  language: AppLanguage,
  categoryId: string,
): Promise<Category | undefined> {
  return fetch(`${CDN_STATIC_FAQ_BABI_URL}/categories/${context}.json`)
    .then((response) => response.json())
    .then((categories: CategoriesResponse) =>
      categories[language].find((category) => category.id === categoryId),
    )
}
