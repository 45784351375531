import React from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Article from 'pages/Article'
import Support from 'pages/Support'
import Error from 'pages/Error'
import { ARTICLES, HOME, SUPPORT } from 'config/routes'
import { AppInfoContextProvider } from 'contexts/AppInfoContext'
import { AppContext, AppLanguage } from 'config/enums'
import { getContext, getLanguage, getTheme } from 'utils/helpers'

import { initTheme, Theme } from '@interco/inter-ui'
import { interWbSession } from '@interco/inter-webview-bridge'

type AppInfo = {
  context: AppContext
  language: AppLanguage
}

function App() {
  const [appInfo, setAppInfo] = React.useState<AppInfo>()

  React.useEffect(() => {
    interWbSession
      .getAppInfo()
      .then((iWbAppInfo) => {
        const { application, organization, language: appLanguage, isDarkMode } = iWbAppInfo

        const context = getContext(application, organization)
        const language = getLanguage(context, appLanguage)
        const theme = getTheme(application)

        initTheme(theme, !!isDarkMode)
        setAppInfo({ context, language })
      })
      .catch(() => {
        initTheme(Theme.PF, false)
        setAppInfo({ context: AppContext.PF, language: AppLanguage.PT })
      })
  }, [])

  return appInfo ? (
    <AppInfoContextProvider {...appInfo}>
      <Router>
        <Routes>
          <Route path="*" element={<Error />} />
          <Route path={HOME}>
            <Route path={ARTICLES}>
              <Route path=":articleId" element={<Article />} />
            </Route>
            <Route path={SUPPORT} element={<Support />} />
          </Route>
        </Routes>
      </Router>
    </AppInfoContextProvider>
  ) : null
}

export default App
