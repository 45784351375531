import styled from 'styled-components'

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  font-family: 'Inter';
  justify-content: center;
  height: calc(100vh - 40px);
  text-align: center;
  width: 100%;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const ErrorBody = styled.div`
  width: 100%;
`

export const ErrorImage = styled.img`
  padding: 24px 78px;
`

export const ErrorTitle = styled.h3`
  color: var(--gray500);
  font-family: 'Sora';
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 8px;
`

export const ErrorDescription = styled.span`
  color: var(--gray400);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`

export const ButtonContainer = styled.div`
  bottom: 24px;
  position: absolute;
  width: calc(100% - 48px);
`
