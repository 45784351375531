import { AppLanguage } from 'config/enums'

import en from './en.json'
import es from './es.json'
import pt from './pt.json'

export const iaLanguages = {
  [AppLanguage.EN]: en,
  [AppLanguage.ES]: es,
  [AppLanguage.PT]: pt,
}
