import React from 'react'

import { PageStatus } from 'config/enums'
import {
  INTER_CHAT_DEEP_LINK_PATH,
  OMBUDSMAN_DEEP_LINK_MESSAGE,
  OMBUDSMAN_WEB_PAGE_URL,
  PF_BASE_DEEP_LINK,
  PJ_BASE_DEEP_LINK,
} from 'config/constants'
import { fetchOmbudsmanCases } from 'services/case'
import { PageRender } from 'components/PageRender'
import { useAppInfo } from 'hooks/useAppInfo'
import { SUPPORT_PAGE_TAG } from 'config/tags'

import ChatIcon from '@interco/icons/orangeds/XL/chat'
import { getCurrentTheme, Theme } from '@interco/inter-ui'
import { Button } from '@interco/inter-ui/components/Button'
import { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'

import * as S from '../styles'
import { Shimmer } from './Shimmer'

export const PfSupport = () => {
  const [userSegmentation, setUserSegmentation] = React.useState<string>()
  const [ombudsmanCases, setOmbudsmanCases] = React.useState<unknown[]>([])
  const [pageStatus, setPageStatus] = React.useState<PageStatus>(PageStatus.LOADING)

  const {
    languageDictionary: { support: dictionary },
  } = useAppInfo()

  React.useEffect(() => {
    fetchOmbudsmanCases()
      .then((cases) => {
        setOmbudsmanCases(cases)
        return interWbSession.getUserInfo()
      })
      .then((userInfo) => {
        setUserSegmentation(userInfo.segmentation)
        setPageStatus(PageStatus.SUCCESS)
      })
      .catch(() => {
        setPageStatus(PageStatus.ERROR)
      })
  }, [])

  const openDeeplink = React.useCallback(
    (deepLink: string, contentName: string) => {
      interWbNavigate.openDeepLink(deepLink)

      interWbSession.requestAnalytics(SUPPORT_PAGE_TAG, {
        content_name: contentName,
        tela: 'Canais de Atendimento',
        content_action: 'clique',
        content_id: userSegmentation || '',
      })
    },
    [userSegmentation],
  )

  const contactOmbudsman = React.useCallback(
    (content: string) => {
      const baseDeepLink = getCurrentTheme().startsWith(Theme.PJ)
        ? PJ_BASE_DEEP_LINK
        : PF_BASE_DEEP_LINK

      openDeeplink(
        `${baseDeepLink}${INTER_CHAT_DEEP_LINK_PATH}?message=${OMBUDSMAN_DEEP_LINK_MESSAGE.replace(
          /\s/g,
          '%20',
        )}`,
        content,
      )
    },
    [openDeeplink],
  )

  const renderOmbudsmanLink = React.useMemo(() => {
    if (ombudsmanCases.length > 0 && getCurrentTheme().startsWith(Theme.PF)) {
      return (
        <>
          <S.SupportChatContainer>
            <Button
              variant="link"
              style={{ padding: '0', width: '100%' }}
              onClick={() => contactOmbudsman(dictionary.ombudsmanOpenChatButtonText)}
              icon={<ChatIcon name="chat" color="var(--primary500)" height="24px" width="24px" />}
            >
              {dictionary.ombudsmanOpenChatButtonText}
            </Button>
          </S.SupportChatContainer>
          <S.AvailabilityContainer>
            <S.AvailabilityInfo>{dictionary.ombudsmanOpenChatAvailabilityInfo}</S.AvailabilityInfo>
          </S.AvailabilityContainer>
          <S.Separator />
        </>
      )
    }

    return null
  }, [
    contactOmbudsman,
    dictionary.ombudsmanOpenChatAvailabilityInfo,
    dictionary.ombudsmanOpenChatButtonText,
    ombudsmanCases.length,
  ])

  return (
    <PageRender pageStatus={pageStatus} loadingPage={<Shimmer />}>
      <S.SupportContainer>
        <S.SupportCard>
          <S.SupportTitle>{dictionary.relationshipCenterTitle}</S.SupportTitle>
          <S.SupportInfo>{dictionary.relationshipCenterDescription}</S.SupportInfo>
          <S.ContactContainer>
            <S.Contact
              onClick={() =>
                openDeeplink(
                  dictionary.relationshipCenterCityContactDeepLink,
                  dictionary.relationshipCenterCityContactInfo,
                )
              }
            >
              {dictionary.relationshipCenterCityContact}
            </S.Contact>
            <S.ContactInfo>{dictionary.relationshipCenterCityContactInfo}</S.ContactInfo>
          </S.ContactContainer>
          <S.ContactContainer>
            <S.Contact
              onClick={() =>
                openDeeplink(
                  dictionary.relationshipCenterOtherLocationsContactDeepLink,
                  dictionary.relationshipCenterOtherLocationsContactInfo,
                )
              }
            >
              {dictionary.relationshipCenterOtherLocationsContact}
            </S.Contact>
            <S.ContactInfo>{dictionary.relationshipCenterOtherLocationsContactInfo}</S.ContactInfo>
          </S.ContactContainer>
          <S.ContactContainer>
            <S.Contact
              onClick={() =>
                openDeeplink(
                  dictionary.relationshipCenterDeficientsContactDeepLink,
                  dictionary.relationshipCenterDeficientsContactInfo,
                )
              }
            >
              {dictionary.relationshipCenterDeficientsContact}
            </S.Contact>
            <S.ContactInfo>{dictionary.relationshipCenterDeficientsContactInfo}</S.ContactInfo>
          </S.ContactContainer>
        </S.SupportCard>

        <S.SupportCard>
          <S.SupportTitle>{dictionary.customerServiceTitle}</S.SupportTitle>
          <S.SupportInfo>{dictionary.customerServiceDescription}</S.SupportInfo>
          <S.ContactContainer>
            <S.Contact
              onClick={() =>
                openDeeplink(
                  dictionary.customerServiceContactDeepLink,
                  dictionary.customerServiceTitle,
                )
              }
            >
              {dictionary.customerServiceContact}
            </S.Contact>
          </S.ContactContainer>
          <S.AvailabilityContainer>
            <S.AvailabilityInfo>
              {dictionary.customerServiceContactAvailabilityInfo}
            </S.AvailabilityInfo>
          </S.AvailabilityContainer>
        </S.SupportCard>

        <S.SupportCard>
          <S.SupportTitle>{dictionary.ombudsmanTitle}</S.SupportTitle>
          <S.SupportInfo>{dictionary.ombudsmanDescription}</S.SupportInfo>
          {renderOmbudsmanLink}
          <S.ContactContainer>
            <S.SupportWebPageLink href="" target="_blank">
              <Button
                variant="link"
                style={{ padding: '0' }}
                onClick={() =>
                  openDeeplink(OMBUDSMAN_WEB_PAGE_URL, dictionary.ombudsmanWebPageButtonText)
                }
              >
                {dictionary.ombudsmanWebPageButtonText}
              </Button>
            </S.SupportWebPageLink>
            <S.AvailabilityContainer>
              <S.AvailabilityInfo>{dictionary.ombudsmanWebPageAvailabilityInfo}</S.AvailabilityInfo>
            </S.AvailabilityContainer>
          </S.ContactContainer>
          <S.Separator />
          <S.ContactContainer>
            <S.Contact
              onClick={() =>
                openDeeplink(dictionary.ombudsmanContactDeepLink, dictionary.ombudsmanTitle)
              }
            >
              {dictionary.ombudsmanContact}
            </S.Contact>
          </S.ContactContainer>
          <S.AvailabilityContainer>
            <S.AvailabilityInfo>{dictionary.ombudsmanAvailabilityInfo}</S.AvailabilityInfo>
          </S.AvailabilityContainer>
        </S.SupportCard>
      </S.SupportContainer>
    </PageRender>
  )
}
