import React from 'react'

import { useAppInfo } from 'hooks/useAppInfo'
import { SUPPORT_PAGE_TAG } from 'config/tags'

import { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'

import * as S from '../styles'

export const IaSupport = () => {
  const {
    languageDictionary: { support: dictionary },
  } = useAppInfo()

  const openDeeplink = React.useCallback((deepLink: string, contentName: string) => {
    interWbNavigate.openDeepLink(deepLink)

    interWbSession.requestAnalytics(SUPPORT_PAGE_TAG, {
      content_name: contentName,
      tela: 'Canais de Atendimento',
      content_action: 'clique',
    })
  }, [])

  return (
    <S.SupportContainer>
      <S.SupportCard>
        <S.SupportTitle>{dictionary.whatsAppTitle}</S.SupportTitle>
        <S.ContactContainer>
          <S.Contact
            onClick={() =>
              openDeeplink(dictionary.whatsAppContactDeepLink, dictionary.whatsAppTitle)
            }
          >
            {dictionary.whatsAppContact}
          </S.Contact>
        </S.ContactContainer>
      </S.SupportCard>

      <S.SupportCard>
        <S.SupportTitle>{dictionary.emailTitle}</S.SupportTitle>
        <S.ContactContainer>
          <S.Contact
            onClick={() => openDeeplink(dictionary.emailContactDeepLink, dictionary.emailTitle)}
          >
            {dictionary.emailContact}
          </S.Contact>
        </S.ContactContainer>
      </S.SupportCard>

      <S.SupportCard>
        <S.SupportTitle>{dictionary.relationshipCenterTitle}</S.SupportTitle>
        <S.SupportInfo>{dictionary.relationshipCenterDescription}</S.SupportInfo>
        <S.ContactContainer>
          <S.Contact
            onClick={() =>
              openDeeplink(
                dictionary.relationshipCenterContactDeeplink,
                dictionary.relationshipCenterTitle,
              )
            }
          >
            {dictionary.relationshipCenterContact}
          </S.Contact>
        </S.ContactContainer>
      </S.SupportCard>

      <S.SupportCard>
        <S.SupportTitle>{dictionary.privacyCenterTitle}</S.SupportTitle>
        <S.SupportInfo>{dictionary.privacyCenterDescription}</S.SupportInfo>
        <S.ContactContainer>
          <S.Contact
            onClick={() =>
              openDeeplink(dictionary.privacyCenterContactDeepLink, dictionary.privacyCenterTitle)
            }
          >
            {dictionary.privacyCenterContact}
          </S.Contact>
        </S.ContactContainer>
      </S.SupportCard>
    </S.SupportContainer>
  )
}
