import styled from 'styled-components'

export const LoaderContainer = styled.div`
  left: calc(50% - 24px);
  position: absolute;
  top: calc(50% - 24px);
`

export const Spinner = styled.div`
  animation: rotation 1s linear infinite;
  border-radius: 50%;
  border-right: 3px solid transparent;
  border-top: 3px solid var(--primary500);
  box-sizing: border-box;
  display: inline-block;
  height: 48px;
  width: 48px;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
