import { AppContext } from 'config/enums'

import { pfLanguages } from './pf'
import { pjLanguages } from './pj'
import { iaLanguages } from './ia'

export const languages = {
  [AppContext.PF]: pfLanguages,
  [AppContext.PJ]: pjLanguages,
  [AppContext.IA]: iaLanguages,
}
