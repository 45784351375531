import React from 'react'

import { useParams } from 'react-router-dom'
import { fetchArticle } from 'services/article'
import LikeIcon from 'assets/images/like.svg'
import LikeOutlineIcon from 'assets/images/like-outline.svg'
import DislikeIcon from 'assets/images/dislike.svg'
import DislikeOutlineIcon from 'assets/images/dislike-outline.svg'
import { fetchCategory } from 'services/category'
import { INTER_CHAT_DEEP_LINK_PATH, PF_BASE_DEEP_LINK, PJ_BASE_DEEP_LINK } from 'config/constants'
import { PageStatus } from 'config/enums'
import { PageRender } from 'components/PageRender'
import { useAppInfo } from 'hooks/useAppInfo'

import { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'
import { Button } from '@interco/inter-ui/components/Button'
import { Article, Category } from '@types'
import { getCurrentTheme, Theme } from '@interco/inter-ui'

import * as S from './styles'

enum FeedbackStatus {
  NONE = 'none',
  LIKE = 'Positivo',
  DISLIKE = 'Negativo',
}

const ARTICLE_TAG = 'Atendimento_Utilidade'

export default function ArticlePage() {
  const [article, setArticle] = React.useState<Article>()
  const [category, setCategory] = React.useState<Category>()
  const [isDomReady, setIsDomReady] = React.useState(false)
  const [pageStatus, setPageStatus] = React.useState<PageStatus>(PageStatus.LOADING)
  const [feedbackStatus, setFeedbackStatus] = React.useState<FeedbackStatus>(FeedbackStatus.NONE)
  const [feedbackStatusAlreadySent, setFeedbackStatusAlreadySent] = React.useState(false)

  const {
    appContext,
    appLanguage,
    languageDictionary: { article: dictionary },
  } = useAppInfo()

  const { articleId } = useParams()

  React.useEffect(() => {
    if (articleId) {
      fetchArticle(appContext, appLanguage, articleId)
        .then((res) => {
          setArticle(res)
          return fetchCategory(appContext, appLanguage, res.category_id)
        })
        .then((res) => {
          setCategory(res)
          setPageStatus(PageStatus.SUCCESS)
          setIsDomReady(true)
        })
        .catch(() => {
          setPageStatus(PageStatus.ERROR)
        })
    } else {
      setPageStatus(PageStatus.ERROR)
    }
  }, [appContext, appLanguage, articleId])

  const anchorClickCallback = React.useCallback((evt: MouseEvent) => {
    evt.preventDefault()
    interWbNavigate.openDeepLink((evt.currentTarget as HTMLAnchorElement).href)
  }, [])

  React.useEffect(() => {
    if (isDomReady) {
      const anchorElements = document.getElementsByTagName('a')

      Array.from(anchorElements).forEach((anchor) => {
        anchor.addEventListener('click', anchorClickCallback)
      })

      return () => {
        Array.from(anchorElements).forEach((anchor) => {
          anchor.removeEventListener('click', anchorClickCallback)
        })
      }
    }

    return undefined
  }, [anchorClickCallback, isDomReady])

  const requestAnalytics = React.useCallback(
    (params: Record<string, string>) => {
      interWbSession.requestAnalytics(ARTICLE_TAG, {
        ...params,
        content_action: 'clique',
        content_category: category?.title as string,
        content_id: articleId as string,
        content_title: article?.title as string,
        tela: 'FAQ Artigos',
      })
    },
    [article?.title, articleId, category?.title],
  )

  const updateFeedbackStatus = React.useCallback(
    (status: FeedbackStatus) => {
      if (!feedbackStatusAlreadySent) {
        requestAnalytics({
          content_name: status,
        })
        setFeedbackStatusAlreadySent(true)
      }

      setFeedbackStatus(status)
    },
    [feedbackStatusAlreadySent, requestAnalytics],
  )

  const openChat = React.useCallback(() => {
    requestAnalytics({
      content_name: 'Conversar via chat',
    })

    const baseDeepLink = getCurrentTheme().startsWith(Theme.PJ)
      ? PJ_BASE_DEEP_LINK
      : PF_BASE_DEEP_LINK

    interWbNavigate.openDeepLink(`${baseDeepLink}${INTER_CHAT_DEEP_LINK_PATH}`)
  }, [requestAnalytics])

  return (
    <PageRender pageStatus={pageStatus}>
      <S.ArticleContainer>
        <S.ArticleTitle>{article?.title}</S.ArticleTitle>
        <S.ArticleContent dangerouslySetInnerHTML={{ __html: article?.content as string }} />
        <S.FeedbackContainer>
          <S.FeedbackMessage>{dictionary.feedbackMessage}</S.FeedbackMessage>
          <S.FeedBackButtonsContainer>
            <S.FeedbackButton onClick={() => updateFeedbackStatus(FeedbackStatus.LIKE)}>
              <S.FeedbackImage
                src={feedbackStatus === FeedbackStatus.LIKE ? LikeIcon : LikeOutlineIcon}
                alt={dictionary.likeIconAltText}
              />
            </S.FeedbackButton>
            <S.FeedbackButton onClick={() => updateFeedbackStatus(FeedbackStatus.DISLIKE)}>
              <S.FeedbackImage
                src={feedbackStatus === FeedbackStatus.DISLIKE ? DislikeIcon : DislikeOutlineIcon}
                alt={dictionary.dislikeIconAltText}
              />
            </S.FeedbackButton>
          </S.FeedBackButtonsContainer>
        </S.FeedbackContainer>
        <Button variant="secondary" style={{ width: '100%' }} onClick={openChat}>
          {dictionary.chatButtonText}
        </Button>
      </S.ArticleContainer>
    </PageRender>
  )
}
