import React from 'react'

import ErrorIcon from 'assets/images/error.svg'
import { ArticleContainer } from 'pages/Article/styles'
import { useAppInfo } from 'hooks/useAppInfo'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Button } from '@interco/inter-ui/components/Button'

import * as S from './styles'

export default function NotFoundPage() {
  const {
    languageDictionary: { error: dictionary },
  } = useAppInfo()

  const requestGoBack = React.useCallback(() => {
    interWbNavigate.requestGoBack()
  }, [])

  return (
    <ArticleContainer>
      <S.ErrorContainer>
        <S.ErrorBody>
          <S.ErrorImage src={ErrorIcon} alt={dictionary.imageAltText} />
          <S.ErrorTitle>{dictionary.title}</S.ErrorTitle>
          <S.ErrorDescription>{dictionary.description}</S.ErrorDescription>
          <S.ButtonContainer>
            <Button variant="primary" style={{ width: '100%' }} onClick={requestGoBack}>
              {dictionary.goBackButtonText}
            </Button>
          </S.ButtonContainer>
        </S.ErrorBody>
      </S.ErrorContainer>
    </ArticleContainer>
  )
}
