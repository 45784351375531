import { CDN_STATIC_FAQ_BABI_URL } from 'config/urls'
import { AppContext, AppLanguage } from 'config/enums'

import { Article } from '@types'

export async function fetchArticle(
  context: AppContext,
  language: AppLanguage,
  articleId: string,
): Promise<Article> {
  const response = await fetch(
    `${CDN_STATIC_FAQ_BABI_URL}/articles/${context}/${language}/${articleId}.json`,
  )

  const article = await response.json()

  return {
    ...article,
    content: article.content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br />'),
  }
}
