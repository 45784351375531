import React from 'react'

import { PageStatus } from 'config/enums'
import ErrorPage from 'pages/Error'
import LoadingPage from 'pages/Loading'

type PageRenderProps = {
  pageStatus: PageStatus
  children: JSX.Element
  errorPage?: JSX.Element
  loadingPage?: JSX.Element
}

export const PageRender = ({ pageStatus, errorPage, loadingPage, children }: PageRenderProps) => {
  switch (pageStatus) {
    case PageStatus.ERROR:
      return errorPage ?? <ErrorPage />

    case PageStatus.LOADING:
      return loadingPage ?? <LoadingPage />

    case PageStatus.SUCCESS:
      return children

    default:
      return null
  }
}
