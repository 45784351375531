export enum AppContext {
  PF = 'pf',
  PJ = 'pj',
  IA = 'ia',
}

export enum AppLanguage {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
}

export enum PageStatus {
  LOADING,
  SUCCESS,
  ERROR,
}
