import styled from 'styled-components'

type SupportProps = {
  isShimmer?: boolean
}

export const SupportContainer = styled.div`
  padding: 24px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const SupportCard = styled.div<SupportProps>`
  border: 1px solid ${({ isShimmer }) => (isShimmer ? 'var(--gray100)' : 'var(--gray200)')};
  border-radius: 8px;
  padding: 16px;

  &:not(:first-child) {
    margin-top: 24px;
  }
`

export const SupportTitle = styled.h3`
  color: var(--gray500);
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
`

export const SupportInfo = styled.p`
  color: var(--gray400);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 16px;
`

export const ContactContainer = styled.div`
  margin-top: 16px;
`

export const Contact = styled.span`
  color: var(--primary500);
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  width: fit-content;
`

export const ContactInfo = styled.span`
  color: var(--gray500);
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`

export const AvailabilityContainer = styled.div`
  line-height: 14px;
  margin-top: 8px;
`

export const AvailabilityInfo = styled.span`
  color: var(--gray400);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`

export const Separator = styled.div`
  border-bottom: 1px solid var(--gray200);
  margin-top: 16px;
  width: 100%;
`

export const SupportChatContainer = styled.div`
  margin-top: 16px;
`

export const SupportWebPageLink = styled.a``
