import React from 'react'

import * as S from './styles'

export default function LoadingPage() {
  return (
    <S.LoaderContainer>
      <S.Spinner />
    </S.LoaderContainer>
  )
}
