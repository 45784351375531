import { useAppInfo } from 'hooks/useAppInfo'
import { AppContext } from 'config/enums'

import { PfSupport } from './pf'
import { PjSupport } from './pj'
import { IaSupport } from './ia'

export default function Support() {
  const { appContext } = useAppInfo()

  switch (appContext) {
    case AppContext.PJ:
      return <PjSupport />

    case AppContext.IA:
      return <IaSupport />

    default:
      return <PfSupport />
  }
}
