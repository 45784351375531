import { createGlobalStyle } from 'styled-components'

const globalStyle = createGlobalStyle`
  body {
    background-color: var(--neutral-theme);
    font-family: 'Inter';
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Sora';
  }

  a {
    color: var(--primary500);
    text-decoration: none;
  }
`

export default globalStyle
