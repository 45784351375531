import React from 'react'

import { Skeleton } from 'components/Skeleton'

import * as S from '../styles'

export const Shimmer = () => (
  <S.SupportContainer>
    <S.SupportCard isShimmer>
      <Skeleton height="19px" />
      <Skeleton height="68px" />
      <Skeleton height="32px" />
      <Skeleton height="32px" />
      <Skeleton height="32px" />
    </S.SupportCard>
    <S.SupportCard isShimmer>
      <Skeleton height="19px" />
      <Skeleton height="51px" />
      <Skeleton height="17px" />
      <Skeleton height="17px" />
    </S.SupportCard>
    <S.SupportCard isShimmer>
      <Skeleton height="19px" />
      <Skeleton height="68px" />
      <Skeleton height="17px" />
      <Skeleton height="17px" />
    </S.SupportCard>
  </S.SupportContainer>
)
