import React from 'react'

import { AppContext, AppLanguage } from 'config/enums'
import { languages } from 'languages'

type AppInfoContextProps = {
  appContext: AppContext
  appLanguage: AppLanguage
  languageDictionary: {
    article: Record<string, string>
    error: Record<string, string>
    support: Record<string, string>
  }
}

type AppInfoContextProviderProps = {
  children: React.ReactNode
  context?: AppContext
  language?: AppLanguage
}

export const AppInfoContext = React.createContext<AppInfoContextProps>({
  appContext: AppContext.PF,
  appLanguage: AppLanguage.PT,
  languageDictionary: languages[AppContext.PF][AppLanguage.PT],
})

export const AppInfoContextProvider = ({
  children,
  context,
  language,
}: AppInfoContextProviderProps) => {
  const [appContext, setAppContext] = React.useState<AppContext>(context ?? AppContext.PF)
  const [appLanguage, setAppLanguage] = React.useState<AppLanguage>(language ?? AppLanguage.PT)

  const provider = {
    appContext,
    changeAppContext: setAppContext,
    appLanguage,
    changeAppLanguage: setAppLanguage,
    languageDictionary: languages[appContext][appLanguage],
  }

  return <AppInfoContext.Provider value={provider}>{children}</AppInfoContext.Provider>
}
